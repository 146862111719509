<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">

    <div class="ppi_pz_header noselect spacerRowTop24">
      <div class="pageZone_title">
        ¡Buen trabajo! Tu información personal está casi completa
      </div>
      <div class="pageZone_subtitle spacerRowTop24">
        Solo debes responder algunas preguntas más, luego podrás revisar la información que proporcionaste para 
        asegurarte de que todo este bien.
      </div>
    </div>

    <ValidationObserver ref="formstepPiM" tag="div"  class="ppi_contentZone spacerRowTop20" v-slot="{ handleSubmit, valid, passed }">
      <q-form 
        ref="myFormPiM"
        @submit.prevent="handleSubmit(doSubmitData)"
        @reset="onStepFormPimReset"
        class="formDetailZone "
      >

        <div class="dataSection_container spacerRowTop48">
          <div class="dataSection_title">
            ¿Cuál es tu estado civil?<!-- agregado en 20-sep-2021 -->
          </div>
          <div class="datasectionItem dataSection_separadorx">
            <div class="row">
                <ValidationProvider ref="fldx_estadoCivil" name="estadocivil" :immediate="false" 
                  class="col-12 canna_forma_campo_container" 
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'max-width: 300px;' : 'max-width: 450px;'"
                  tag="div" style="margin-left: -10px;"
                  :rules=" { required: true, oneOf: z_allowEstadocivil } "
                  v-slot="{ errors, invalid, validated  }"
                >
                    <input type="hidden" name="estadocivil"  v-model="frmData.estadoCivil">
                    <q-option-group
                      class="canna_optionGroup_A fldx_campoTieneLicenciaYesNO"
                      :inline="true"
                      name="tieneTCOpt"
                      type="radio"
                      keep-color
                      :color="(invalid && validated && errors[0] !==undefined || frmData.estadoCivil===null) ? 'cannaRadioError' : 'canna_ColorRadio'"
                      :options="cat_edocivilAlt"
                      v-model="frmData.estadoCivil"
                    />
                    <template v-if="(invalid && validated && errors[0] !==undefined)">
                      <div class="q-field--error q-field__bottom" 
                        style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 10px; display: flex; align-items: center;"
                      >
                      {{ errors[0].replace('El campo','').replace('estadocivil','Este dato') }}
                      </div>
                    </template>
                </ValidationProvider>
            </div>
          </div>
        </div>

        <!-- dev-sprint3 nuevo dato DIC2021 -->
        <div class="dataSection_container spacerRowTop40">
          <div class="dataSection_title">
            ¿Tienes crédito Infonavit vigente?
          </div>
          <div class="datasectionItem dataSection_separadorx">
            <div class="row">
                <ValidationProvider ref="fldx_infonavit" name="tieneinfonavit" :immediate="false" class="col-12 canna_forma_campo_container" tag="div" style="margin-left: -10px;"
                  :rules=" { required: true, oneOf: z_allowInfonavit } "
                  v-slot="{ errors, invalid, validated  }"
                >
                    <input type="hidden" name="trabajado"  v-model="frmData.tieneInfonavit">
                    <q-option-group
                      class="canna_optionGroup_A fldx_campoTieneLicenciaYesNO"
                      inline
                      name="tieneTCOpt"
                      type="radio"
                      keep-color
                      :options="[
                        { label: 'Si', value: 965, color:(invalid && validated && errors[0] !==undefined || frmData.tieneInfonavit===null) ? 'cannaRadioError' : 'canna_ColorRadio' }, 
                        { label: 'No', value: 966, color:(invalid && validated && errors[0] !==undefined || frmData.tieneInfonavit===null) ? 'cannaRadioError' : 'canna_ColorRadio' }
                      ]"
                      v-model="frmData.tieneInfonavit"
                    />
                    <template v-if="(invalid && validated && errors[0] !==undefined)">
                      <div class="q-field--error q-field__bottom" 
                        style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 10px; display: flex; align-items: center;"
                      >
                      {{ errors[0].replace('El campo','').replace('tieneinfonavit','Este dato') }}
                      </div>
                    </template>
                </ValidationProvider>
            </div>
          </div>
        </div>



        <div class="dataSection_container spacerRowTop40">
          <div class="dataSection_title">
            ¿Anteriormente has trabajado con nosotros?
            <!-- ¿En el pasado has trabajado en alguna empresa de nuestra compañía? -->
          </div>
          <div class="datasectionItem dataSection_separadorx">
            <div class="row">
                <ValidationProvider ref="fldx_trabajadoAP" name="trabajado" :immediate="false" class="col-12 canna_forma_campo_container" tag="div" style="margin-left: -10px;"
                  :rules=" { required: true, oneOf: z_allowtrabajado } "
                  v-slot="{ errors, invalid, validated  }"
                >
                    <input type="hidden" name="trabajado"  v-model="frmData.trabajadoNos">
                    <q-option-group
                      class="canna_optionGroup_A fldx_campoTieneLicenciaYesNO"
                      inline
                      name="tieneTCOpt"
                      type="radio"
                      keep-color
                      :options="[
                        { label: 'Si', value: 756, color:(invalid && validated && errors[0] !==undefined || frmData.trabajadoNos===null) ? 'cannaRadioError' : 'canna_ColorRadio' }, 
                        { label: 'No', value: 757, color:(invalid && validated && errors[0] !==undefined || frmData.trabajadoNos===null) ? 'cannaRadioError' : 'canna_ColorRadio' }
                      ]"
                      v-model="frmData.trabajadoNos"
                    />
                    <template v-if="(invalid && validated && errors[0] !==undefined)">
                      <div class="q-field--error q-field__bottom" 
                        style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 10px; display: flex; align-items: center;"
                      >
                      {{ errors[0].replace('El campo','').replace('trabajado','Este dato') }}
                      </div>
                    </template>
                </ValidationProvider>
            </div>
          </div>
        </div>

        <!-- Validador general de la forma --> 
        <ValidationProvider 
          tag='div' :immediate="true"
          name="socialesAll"
          :ref="'fldx_socialesall'"
          :rules=" { 
            required:true, 
            cannaQuestSocialesMin:1,
            cannaQuestSocialesMax:5
          }"
           v-slot="{ errors, invalid, validated }"
        >
          <input type="hidden" name="socialesAll" v-model="frmData.sociales">
          <template v-if="(invalid && validated && errors[0] !==undefined)">
            <div class="notifyZone noselect notifyZoneRemove" style="margin-left: 0px;width: 100% !important;">
              <div style="flex: 1 1 0%; justify-content: center;padding: 0px 20px;display: flex;align-items: center;">
                  {{ pim_socialesAllMsg(errors[0]) }}
              </div>
              <div style="margin-right: 14px; display:none;" >
                  <div class="closenotifyZoneIcon action_item" @click.stop="pim_doResetSocialesAll">
                    <q-icon name="clear" style="color:#ffffff; font-size: 20px;" />
                  </div>
              </div>
            </div>
          </template>
        </ValidationProvider>

        <div class="dataSection_container">

          <div class="dataSection_title">¿Cómo te enteraste de nosotros?</div>
          <div class="datasectionItem dataSection_separadorx">
            <div class="row" :style="(is_Mobile === true || $q.screen.lt.md) ? '' : 'max-width:650px'">
              <template v-for="(redsocial, index) in cat_Sociales.dataCatalog">
                <div class="col-xs-12 col-md-6" :key="'SocialesChk_' + index">
                  <template v-if="redsocial.id===21">

                    <q-checkbox v-model="frmData.sociales"
                      :label="redsocial.nombre" color="cannaMainColor" :val="redsocial.id" 
                      @input="pib_changeSocialesOtros"
                    />

                    <div style="padding-left: 10px;">
                      <ValidationProvider 
                        name="socialesOtro" :immediate="false" :ref="'fldx_socialesOtro'"
                        :rules=" {  required: (val_socialesHasOtros===false) ? false : true, min:4, max:40, cannaTextOtros:true }"
                        v-slot="{ errors, invalid, validated  }"
                      >
                        <q-input
                          filled
                          name="socialesOtro"
                          maxlength="40"
                          v-model="frmData.sociales_otro"
                          hint=""
                          :dense="true"
                          :label="(frmData.sociales_otro==='' || frmData.sociales_otro=== null)?'Por favor, especifica':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? errors[0].replace('socialesOtro','Otro (descripción)') : '' "
                          :disable="(val_socialesHasOtros===false)? true: false"
                        />
                      </ValidationProvider>
                    </div>
                  </template>
                  <template v-else>
                    <q-checkbox v-model="frmData.sociales" 
                      :label="redsocial.nombre" color="cannaMainColor" :val="redsocial.id" 
                    />
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="ppi_pz_footer">
          <div class="rowButtons">
            <q-btn type="button" outline unelevated color="app_mainBtn" icon="r_chevron_left" label="Atrás" no-caps @click.stop="doPrevScreen"/> 
            <div>
              <!--
              <q-btn type="button" outline unelevated color="app_mainBtn" icon="r_chevron_right" label="tester" no-caps @click.stop="doSubmitData"/> 
              -->
              <q-btn type="submit" unelevated color="app_mainBtn" icon-right="r_chevron_right" 
                label="Revisar mi información" no-caps 
                :disable="((passed || valid) )? false : true"
              />
            </div>
          </div>
        </div>

      </q-form>
    </ValidationObserver>

  </div>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from '../../../../boot/axios' // APP code 
import logOut_Mixin from '../../../../mixins/requestMix.js'
import myplatform_Mixin from '../../../../mixins/platformMix.js'
import helperBase_Mixin from '../../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../../mixins/helperCatsMix.js'

const tplpim_Data = {
  tieneInfonavit: null,
  trabajadoNos: null,
  sociales: [],
  sociales_otro: '',
  estadoCivil: null 
}

export default {
  name: 'psi_screen_sociales',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin
  ],
  components:{

  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      //-----------
      myDifferify: differify,
      //-----------
      zloadingData: false,
      processingData: false,
      //-----------
      zRef_tallasU: [],
      z_allowtrabajado: [756,757],
      z_allowInfonavit:[965,966],
      z_allowSociales: [],
      z_allowEstadocivil:[],
      list_edocivilWeb:[],
      list_edocivilMobile:[],
      profileRecords:[], //Los registros previamente guardados en este perfil 
      frmDataOriginal: JSON.parse(JSON.stringify(tplpim_Data)),
      frmData: JSON.parse(JSON.stringify(tplpim_Data)), 
    }
  },
  computed: {
    cat_TrabajadoNos () { // catalogo id = 62 Ha trabajado con nosotros  (SI: 756   NO: 757)
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(1) : this.getHelperCat(1)
    },
    cat_Sociales () { // catalogo id = 4 RedesSociales  -- Otro: 21 
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(4) : this.getHelperCat(4)
    }, 
    cat_edocivil (){ // catalogo id = 9	Estado civil
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(9) : this.getHelperCat(9)
    },
    cat_edocivilAlt(){
      return (this.is_Mobile === true || this.$q.screen.lt.md) ? this.list_edocivilMobile : this.list_edocivilWeb
    },
    val_socialesHasOtros () {
      var test = false
      if (this.frmData.sociales !== undefined) {
        if (this.frmData.sociales.length) {
          test = (this.frmData.sociales.indexOf(21) >= 0) ? true : false
        }
      }
      return test
    },
    manformTest(){
      return this.$refs.formstepPiM
    },
    mainErrors () {
      var countx = 0
      if (this.zinitScreen) {
        try {
          if (this.$refs.formstepPiM) {
            if (this.$refs.formstepPiM.errors) {
              var OBJKeys = Object.keys(this.$refs.formstepPiM.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formstepPiM.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    }
  },
  watch: {

  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- psiPage_sociales -- test')
    },
    showLoadx ( value, type ){
      // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    doPrevScreen(){
      var newRuta = '/dashboard/personal/aptitudes-d'
      this.$cannaDebug('-- psiPage_sociales -- do Prev Screen --- ' + newRuta)
      this.$router.push(newRuta)
    },
    onStepFormPimReset () { 

    },
    pim_doResetSocialesAll:function(){ 
      if (this.$refs.fldx_socialesall){
        this.$refs.fldx_socialesall.reset()
      }
    },
    pim_socialesAllMsg:function( errorx ){ 
      if (errorx === undefined || errorx === null || errorx ===''){ 
        return ''
      }
      var strbase = errorx.replace('socialesAll','').replace('El campo','')
      if (strbase.trim() ==='es obligatorio'){ 
        strbase = '¡Espera! Debes seleccionar mínimo 1 opcion en la sección ¿Cómo te enteraste de nosotros?.'
      } //var test == (errors[0] !==undefined) ? errors[0] : ''
      return strbase
    },
    pib_changeSocialesOtros: function (valuex, event) {
      this.$cannaDebug('-- psiPage_sociales --   change Sociales Otros --- ')
      var test = 11
      var currval = valuex 
      if (currval.indexOf(21) < 0) {
        this.frmData.sociales_otro = null
        this.$refs.fldx_socialesOtro[0].reset()
      }
      this.$forceUpdate()
      setTimeout(() => {
        this.$refs.fldx_socialesOtro[0].validate().then((response) => { 
          test = 22
        })
      }, 200)
    },
    async doSubmitData () {
      this.$cannaDebug('-- psiPage_sociales -- do Submit Data --- ' + newRuta)
      var test = 11 
      var toAdd = [], toRemove = [], toPatch = []
      var allowNextPage = false 
      if (this.mainErrors > 0) {
        return false
      }
      this.showLoadx(true, 1)

      var originalRecords = JSON.parse(JSON.stringify(this.profileRecords))
      var originalValues = JSON.parse(JSON.stringify(this.frmDataOriginal))
      var newValues =  JSON.parse(JSON.stringify(this.frmData)) 
      var testA = this.myDifferify.compare(originalValues, newValues)
      if (testA.changes > 0 ) { // Que existan valores...!

        // Estado Civil
        if (testA._.estadoCivil.status === 'MODIFIED') {
          var item = testA._.estadoCivil 
          if (item.status === 'ADDED') {
            toAdd.push(item.current)
          } else if (item.status === 'MODIFIED') {
            if (this.profileRecords.length > 0 &&  (item.original!=='' && item.original!==null)){ 
              toRemove.push(item.original)
            }
            toAdd.push(item.current)
          } else if (item.status === 'DELETED') {
            toRemove.push(item.original)
          }
        }
        // Tiene infonavit vigente //dev-sprint3 dic 2021
        if (testA._.tieneInfonavit.status === 'MODIFIED') {
          var item = testA._.tieneInfonavit 
          if (item.status === 'ADDED') {
            toAdd.push(item.current)
          } else if (item.status === 'MODIFIED') {
            if (this.profileRecords.length > 0 &&  (item.original!=='' && item.original!==null)){ 
              toRemove.push(item.original)
            }
            toAdd.push(item.current)
          } else if (item.status === 'DELETED') {
            toRemove.push(item.original)
          }
        }
        // Trabajado con nosotros
        if (testA._.trabajadoNos.status === 'MODIFIED') {
          var item = testA._.trabajadoNos 
          if (item.status === 'ADDED') {
            toAdd.push(item.current)
          } else if (item.status === 'MODIFIED') {
            if (this.profileRecords.length > 0 &&  (item.original!=='' && item.original!==null)){ 
              toRemove.push(item.original)
            }
            toAdd.push(item.current)
          } else if (item.status === 'DELETED') {
            toRemove.push(item.original)
          }
        }
        // Sociales 
        if (testA._.sociales.status === 'MODIFIED') {
          testA._.sociales._.forEach(item => {
            if (item.status === 'ADDED') {
              if (item.current === 21) {
                toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.sociales_otro })
              } else {
                toAdd.push(item.current)
              }
            } else if (item.status === 'MODIFIED') {
              toRemove.push(item.original)
              if (item.current === 21) {
                toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.sociales_otro })
              } else {
                toAdd.push(item.current)
              }
            } else if (item.status === 'DELETED') {
              toRemove.push(item.original)
            }
          })
        }

        // sociales_otro si aplica patch 
        if (testA._.sociales_otro.status === 'MODIFIED') {
          var tmpArrW = toRemove.indexOf(21)
          var tmpArrZ = this.profileRecords.filter(item => item.catalogo_id === 21) // Tiene que existir
          if (tmpArrZ.length > 0 && tmpArrW < 0) { // Solo si existe el registro ..! para aplicar en PATCH
            toPatch.push([tmpArrZ[0].catalogo_id, this.frmData.sociales_otro])
          }
        }
        // -----------
        // Procesamos los datos...! 
        test = 44
        var reqRes = { action: [],  rmovex: [], patch: [], addx: [] }
        var reqErr = { action: 0, rmovex: 0, patch: 0, addx: 0 }
        var mcModel = this.getModelRequest('profileFiltro')

        // ----------------------
        // ----------------------
        // Registros a eliminar...! 
        if (toRemove.length > 0) {
          var toDelReqs = []
          for (const delRC of toRemove) {
            toDelReqs.push(this.createAxiosConfig ('remove', mcModel, { delimiters: [delRC] }, true, null))
          }
          for (const delRQx of toDelReqs) {
            if (reqErr.rmovex <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataRemove', reqRes.rmovex, delRQx).then((resx) => {
                var tmpresponseData = reqRes.rmovex[reqRes.rmovex.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.rmovex += 1
                }
              })
            }
          }
          if (reqErr.rmovex > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.rmovex[0], reqErr.rmovex)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // lo que se hace path 
        if (toPatch.length > 0) {
          var toPatchReqs = []
          for (const pxRC of toPatch) {
            toPatchReqs.push(this.createAxiosConfig ('patch', mcModel, { 
              delimiters: [pxRC[0]],
              data: { otro: '' + pxRC[1] }
            }, true, null))
          }
          for (const patchRQx of toPatchReqs) {
            if (reqErr.patch <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataPatch', reqRes.patch, patchRQx).then((resx) => {
                var tmpresponseData = reqRes.patch[reqRes.patch.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.patch += 1
                }
              })
            }
          }
          if (reqErr.patch > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.patch[0], reqErr.patch)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // lo que se agrega 
        if (toAdd.length > 0) {
          var toAddReqs = []
          for (const addRC of toAdd) {
            var addParams = null
            var testObject = (addRC !== null && typeof addRC === 'object' && Array.isArray(addRC) === false) ? true : false 
            if (testObject === true) { // Objetos como (Otros) que llevan 2 valores
              addParams = addRC
            } else {
              addParams = {catalogo_id: addRC}
            }
            toAddReqs.push(this.createAxiosConfig ('post', mcModel, { delimiters: [], data: addParams }, true, null))
          }
          for (const addRQx of toAddReqs) {
            if (reqErr.addx <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataAdd', reqRes.addx, addRQx).then((resx) => {
                var tmpresponseData = reqRes.addx[reqRes.addx.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.addx += 1
                }
              })
            }
          }
          if (reqErr.addx > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.addx[0], reqErr.addx)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // Terminamos
        // -----------
        this.frmDataOriginal = JSON.parse(JSON.stringify(this.frmData))
        allowNextPage = true
      } else { // seguimos a la siguiente pantalla 
        test = 33
        allowNextPage = true
      }
      // Actualizar Datos de Estatus // WIP 
      var retUPS = null
      if (this.currProfile_status < this.zstatUser.REDES_SOCIALES){ // < 25 -> 26 
        await this.hpb_updateProfileStatus( this.zstatUser.REDES_SOCIALES ).then(respx=>{
          retUPS = respx
        })
      }
      // -----------
      this.showLoadx(false, 1)
      if (allowNextPage) {
        var newRuta = '/dashboard/personal/revision'
        this.$router.push(newRuta)
      }
      return true
    },
    async initFormData () {
      var test=11
      var mcModel = this.getModelRequest('profileFiltro')
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }

      // catalogo id = 62 Ha trabajado con nosotros  (SI: 756   NO: 757)
      // catalogo id = 4 RedesSociales  -- Otro: 21 

      // Hasta aqui estamos bien y obtenemos los datos de profile
      // NOTA CATEG 63 (universales no se usa en datos)
      var myFormConfig = this.createAxiosConfig ('get', mcModel, { categoria_id: '62,4,9,78', 'per-page': 'all', expand:'catalogo'}, true, null)

      await this.doRequestByPromise(this.$apiV2, 'pibData', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })

      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpii_Data))
        this.frmData = JSON.parse(JSON.stringify(tplpii_Data))
        return false 
      }
      var profileData = reqRes.action[0].data.data
      this.profileRecords = JSON.parse(JSON.stringify(profileData))
      //-----------------------------

      if (Array.isArray(profileData) && profileData.length>=1){ 

        var workwithUs  = null 
        var socialVals = []
        var sociales_otrox = null
        var edoCivilVal = null 
        var infonavitHas = null 


        profileData.forEach(itemP => {
          if (itemP.categoriaId === 62) { // Ha trabajado con nosotros
            workwithUs = itemP.catalogo_id
          } else if (itemP.categoriaId === 78){
            infonavitHas = itemP.catalogo_id
          } else if (itemP.categoriaId === 9){
            edoCivilVal = itemP.catalogo_id
          }
        })

        profileData.forEach(itemP => {
            if (itemP.categoriaId === 4) { //sociales
              socialVals.push(itemP.catalogo_id)
              if (itemP.catalogo_id === 21){ 
                sociales_otrox = '' + itemP.otro 
              }
            }
        })

        var newData = {
          tieneInfonavit: infonavitHas,
          trabajadoNos: workwithUs,
          sociales: socialVals,
          sociales_otro: sociales_otrox,
          estadoCivil: edoCivilVal
        }
        this.frmDataOriginal = JSON.parse(JSON.stringify(newData))
        this.frmData = JSON.parse(JSON.stringify(newData))

        this.$forceUpdate()
        await this._waitRequestProc(200)

      } else { // No hay info todavia  //se usa el sexo por default 

        var baseDataUse = JSON.parse(JSON.stringify(tplpim_Data))
        this.frmDataOriginal = JSON.parse(JSON.stringify(baseDataUse))
        this.frmData = JSON.parse(JSON.stringify(baseDataUse))

      }
      this.zinitData = true
      return true
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- psiPage_sociales -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_sociales -- created')
    this.zloadingData = true 
    this.zinitScreen = false
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_sociales --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_sociales -- mounted')
    this.zloadingData = false
    this.showLoadx(true, 2)
    var categorias = [62,4,9] 

    if (this.$refs.fldx_socialesall){ 
      this.$refs.fldx_socialesall.reset()
    }
    if (this.$refs.fldx_trabajadoAP){
      this.$refs.fldx_trabajadoAP.reset()
    }
    for (let categx of categorias) {
      if (this.getHelperCat(categx).dataLoaded===false){ 
        var testCatA = false 
        await this.helpermix_getCatalog (categx, null).then( respx => { 
          this.$cannaDebug('-- psiPage_sociales -- categoria ' + categx  + ' finish ')
          testCatA = respx 
        })
      }
      await this._waitRequestProc(200)
    }
    var allowedVals = []
    //----------- Estado civil 
    var listaWeb = []
    var listaMobile=[]
    this.getHelperCat(9).dataCatalog.forEach( item => { 
      allowedVals.push(item.id)
      listaWeb.push({ label: '' + item.nombre, value:  item.id, orden: item.orden_web })
    })
    this.getHelperCatMobil(9).dataCatalog.forEach( item => { 
      listaMobile.push({ label: '' + item.nombre, value:  item.id, orden: item.orden_movil})
    })

    this.z_allowEstadocivil = allowedVals.slice(0)
    this.list_edocivilWeb = listaWeb 
    this.list_edocivilMobile = listaMobile

    //----------- Trabajado nos
    allowedVals = []
    this.getHelperCat(62).dataCatalog.forEach( item => { 
      allowedVals.push(item.id)
    })
    this.z_allowtrabajado = allowedVals.slice(0)
    allowedVals = []
    //-----------  Sociales
    this.getHelperCat(4).dataCatalog.forEach( item => { 
      allowedVals.push(item.id)
    })
    this.z_allowSociales = allowedVals.slice(0)
    allowedVals = []
    // ----------------
    await this._waitRequestProc(200)
    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    this.$forceUpdate()
    await this._waitRequestProc(200)

    if (this.$refs.fldx_socialesall){
      this.$refs.fldx_socialesall.reset()
    }
    if (this.$refs.fldx_trabajadoAP){
      this.$refs.fldx_trabajadoAP.reset()
    }
    this.zloadingData = false
    this.showLoadx(false, 2)
    this.zinitScreen = true
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_sociales -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_sociales -- updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_sociales -- activated')
    if (this.zinitScreen === true){
      this.zloadingData = true
      this.showLoadx(true, 1)
      await this._waitRequestProc(50)

      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })
      this.$forceUpdate()

      await this._waitRequestProc(200)

      this.zloadingData = false
      this.showLoadx(false, 1)
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_sociales -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_sociales -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_sociales -- destroyed')
  }
}
</script>
